.table {
  color: #333 !important;
}

.table > :not(:first-child) {
  border-top: 2px solid lightgray !important;
}

h4 caption {
  color: black;
}

a:hover {
  text-decoration: underline;
}

#show_history_switch:checked,
#show_coming_switch:checked {
  background-color: #0e688b;
  border-color: #0e688b;
}

h3,
h4 {
  margin: 10px 10px 10px 0 !important;
  color: #0e688b;
}

th {
  color: #0e688b;
}

.treeNode {
  list-style: none;
  padding-left: 0;
  margin-bottom: inherit;
}

.treeHierarchies {
  color: #953000;
  font-size: 0.86em;
  margin-left: 5px;
}

.warningText {
  color: red;
}

.successText {
  color: green;
}

.skip-nav-link {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.skip-nav-link:focus-visible,
.skip-nav-link:active {
  background: #107eab;
  color: white;
  border: 50px;
  clip: auto;
  height: auto;
  margin: 50px;
  padding: 1rem;
  position: fixed;
  width: auto;
  z-index: 1001;
}

.navbar {
  flex-flow: column;
  display: grid;
}
