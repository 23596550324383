/* Revert Bootstrap smooth scroll */
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow {
  border: solid #107eab;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  background: transparent;
  margin: auto;
  position: relative;
  bottom: 5%;
}

.arrow::after {
  position: absolute;
  content: '';
  left: -4px;
  top: -4px;
  z-index: -1;
  width: 300%;
  height: 300%;
  border-radius: 7px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.boldHeader {
  color: #0e688b;
  font-weight: bold;
  text-decoration: none;
}

.boldHeader:hover {
  cursor: pointer;
}

.littleLeftMargin {
  margin-left: 5px;
}

.error {
  color: red;
}

fieldset {
  border: none;
}

.texts-default {
  background-color: #f8f8f8;
}
